<template>
	<v-form ref="form" v-model="isValid" @submit.prevent="onSubmit">
		<w-layout row wrap>
			<w-flex v-for="(field, index) in fields" :key="index" xs12>
				<v-layout row wrap>
					<w-text-info v-if="field.info" class="mx-1" mini :text="$t(field.info)" />
					<w-password-input
						v-if="field.type == 'password'"
						v-model.trim="copiedValue[field.name]"
						:disabled="field.readonly"
						:label="field.label"
						:regex="field.regex"
						:required="field.required"
						@input="onInput(field, $event)"
					/>
					<w-text-input
						v-else-if="field.type == 'text'"
						v-model.trim="copiedValue[field.name]"
						:disabled="field.readonly"
						:label="field.label"
						:prefix="field.prefix"
						:regex="field.regex"
						:required="field.required"
						:suffix="field.suffix"
						:tooltip="field.tooltip"
						@input="onInput(field, $event)"
					/>
					<w-email-input
						v-else-if="field.type == 'email' && !field.multiple"
						v-model.trim="copiedValue[field.name]"
						:disabled="field.readonly"
						:label="field.label"
						:required="field.required"
						:rules="field.rules"
						:validate-on-blur="validateOnBlur"
						@input="onInput(field, $event)"
					/>
					<w-multiple-email-input
						v-else-if="field.type == 'email' && field.multiple"
						v-model.trim="copiedValue[field.name]"
						:disabled="field.readonly"
						:label="field.label"
						:required="field.required"
						:validate-on-blur="validateOnBlur"
						@input="onInput(field, $event)"
					/>
					<w-phone-input
						v-else-if="field.type == 'phone'"
						v-model="copiedValue[field.name]"
						:disabled="field.readonly"
						:label="field.label"
						:required="field.required"
						@input="onInput(field, $event)"
					/>
					<w-url-input
						v-else-if="field.type == 'url' && !field.items"
						v-model.trim="copiedValue[field.name]"
						:disabled="field.readonly"
						:label="field.label"
						:prefix="!field.readonly ? field.prefix : undefined"
						:regex="field.regex"
						:required="field.required"
						type="url"
						@input="onInput(field, $event)"
					/>
					<w-select
						v-else-if="field.type == 'list'"
						v-model="copiedValue[field.name]"
						:disabled="field.readonly"
						:label="field.label"
						:items="field.items"
						item-text="text"
						item-value="value"
						:required="field.required"
						@input="onInput(field, $event)"
					/>
					<w-autocomplete
						v-else-if="field.type == 'autocomplete'"
						v-model="copiedValue[field.name]"
						:disabled="field.readonly"
						:label="field.label"
						:items="field.items"
						item-text="text"
						item-value="value"
						:required="field.required"
						@input="onInput(field, $event)"
					/>
					<w-combobox
						v-else-if="field.type == 'combobox' || field.items"
						v-model="copiedValue[field.name]"
						:disabled="field.readonly"
						:label="field.label"
						:items="field.items"
						item-text="text"
						item-value="value"
						:required="field.required"
						:rules="field.type == 'url' ? [rules.url] : []"
						@input="onInput(field, $event)"
					/>
					<w-text-info v-else-if="field.type == 'text-info'" :text="field.text" :color="field.color" :icon="field.color" />
					<w-btn v-if="field.copy" flat icon="far fa-copy" mini @click="copy(copiedValue[field.name])">
						{{ $t('automation.copy') }}
					</w-btn>
				</v-layout>
			</w-flex>
		</w-layout>
	</v-form>
</template>

<script>
import Validator from '@/mixins/Validator'
import AppService from '@/services/AppService'
import { Events as EVENTS, Bus as BUS } from '@/events/AppEvents'

/**
 * @displayName w-form-builder
 *
 * @since 0.3.2
 */
export default {
	name: 'WFormBuilder',
	mixins: [Validator],
	props: {
		fields: {
			required: true,
			type: Array
		},
		validateOnBlur: {
			default: true,
			required: false,
			type: Boolean
		},
		validation: {
			required: false,
			type: Boolean,
			default: () => false
		},
		value: {
			required: false,
			type: Object,
			default: () => ({})
		}
	},
	computed: {
		copiedValue: function () {
			return { ...this.value }
		},
		isValid: {
			get: function () {
				return this.validation
			},
			set: function (value) {
				this.$emit('update:validation', value)
			}
		}
	},
	methods: {
		copy: function (value) {
			AppService.copyToClipboard(value).then(() => {
				BUS.emit(EVENTS.SNACKBAR_SUCCESS, this.$t('automation.copied'))
			})
		},
		onInput: function (field, newVal) {
			let newValue = { ...this.copiedValue }
			newValue[field.name] = newVal
			this.$emit('input', newValue)
		},
		onSubmit: function (event) {
			if (this.isValid) {
				this.$emit('submit', event)
			}
		},
		reset: function () {
			this.$refs.form.reset()
		},
		resetValidation: function () {
			this.$refs.form.resetValidation()
		}
	}
}
</script>
